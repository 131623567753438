@import 'https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap';
@import '~antd/dist/antd.css';


*{ margin:0; padding:0;}
/* html, body, #root, .App{ min-height:100vh;} */
html body{font-family:'Raleway'; font-size:100%; line-height:100%; background-color:#f5f5f5;}
button{cursor:pointer;}
.flex-wrap{ flex-wrap:wrap;}
.ml-auto{ margin-left:auto !important;}

a:hover{color:#ee8763;}
button:focus, img:focus, input:focus, select:focus, textarea:focus, a:focus, :focus { outline:0;}

.justify-content-center{-ms-flex-pack: center!important; justify-content: center!important;}

.login-page{ background:url('/images/login-bg.jpg') no-repeat center 50% / cover; padding:150px 0; min-height:100%;}
.login-box{ background:#fff; box-shadow:0 0 20px 0 rgba(0,0,0,.4); display:block; max-width:450px; margin:0 auto;}
.login-box h2{ padding:45px 0; margin:0; display:block; text-align:center; color:#ee8763; font-size:28px; line-height:100%; text-transform:uppercase;}

.login-box .ant-form{ padding:0 40px;}
.ant-row.ant-form-item { margin:0 0 30px;}
ul.filled-ul li label,
.ant-form-item-control label{ margin:0 0 5px; padding:0; display:block; font-size:14px; color:#999; line-height:26px; letter-spacing:.04em; font-weight:600;font-family:'Raleway'; position:relative;}
.ant-form-item-control-wrapper .ant-form-item-control textarea,
.ant-form-item-control-wrapper .ant-form-item-control input{ border-radius:4px; box-shadow:2px 4px 4px 0 rgba(0,0,0,.1) !important; width:100%; border:1px solid #dedcdc; padding:10px 15px !important; line-height:24px; font-size:14px; color:#666; font-weight:500; height:auto; font-family:'Raleway'; letter-spacing:.04em; opacity:1;}
.ant-form-item-control-wrapper .ant-form-item-control textarea:hover,
.ant-form-item-control-wrapper .ant-form-item-control input:hover{ border-color:#ee8763 !important;}
.ant-form-item-control-wrapper .ant-form-item-control textarea{ height:180px;vertical-align:top; line-height:1.5rem;}

.ant-form-item-control-wrapper .ant-form-item-control textarea::-webkit-input-placeholder,
.ant-form-item-control-wrapper .ant-form-item-control input::-webkit-input-placeholder { color:#999;}

.ant-form-item-control-wrapper .ant-form-item-control textarea::-moz-placeholder,
.ant-form-item-control-wrapper .ant-form-item-control input::-moz-placeholder { color:#999;}

.ant-form-item-control-wrapper .ant-form-item-control textarea:-ms-input-placeholder,
.ant-form-item-control-wrapper .ant-form-item-control input:-ms-input-placeholder { color:#999;}

.ant-form-item-control-wrapper .ant-form-item-control textarea:-moz-placeholder,
.ant-form-item-control-wrapper .ant-form-item-control input:-moz-placeholder { color:#999;}

div.ant-row.ant-form-item.login-submit-btn{ margin:50px -40px 0; padding:30px 40px; background:#ee8763;}
div.ant-row.ant-form-item.login-submit-btn button[type="submit"]{ background: #fff; border-radius: 4px; border: 2px solid #fff; color: #666666; font-size:14px; font-weight:500; text-transform:uppercase; width:auto; padding:10px 20px; height:auto; margin:0 0 0 auto; display:block;}
div.ant-row.ant-form-item.login-submit-btn button[type="submit"]:hover{ background:transparent; color:#fff; }
div.ant-row.ant-form-item.login-submit-btn .ant-form-item-children{ text-align:center; display:flex; align-items:center;} 
a.login-form-forgot{ color:#fff; text-decoration:none; margin:0 ; font-size:14px; text-transform:uppercase; font-weight:500; letter-spacing:.08em;}
a.login-form-forgot:hover{ text-decoration:underline; color:#fff;}

.has-error .ant-form-explain, .has-error .ant-form-split {margin: 7px 0 0 4px;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #dedcdc; 
  -webkit-text-fill-color: #999; 
  -webkit-box-shadow: 0 0 0px 1000px #fff inset; 
  transition: background-color 5000s ease-in-out 0s;
} 

.d-flex { display: -ms-flexbox!important; display:flex!important;}
.content-wrap { position:relative; padding:85px 30px 0 320px; transition:ease-in-out .5s;}
.header-top { position:absolute; top:25px; left:320px; width:calc(100% - 350px); transition:ease-in-out .5s;}

.sidebar-toggle{ cursor:pointer; transition:ease-in-out .5s; position:fixed; top:36px; left:232px; z-index:99;}
.sidebar-toggle button{ border:0; background:transparent;}
.sidebar-toggle button svg path{ fill:rgb(73, 77, 75); }
.sidebar-toggle button svg:hover path{ fill:#ee8763;}

.openSider .sidebar-toggle{box-shadow: 0 5px 20px 0 rgba(0,0,0,.1); border-radius:4px; left:30px; background: #fff; padding:12px 14px 11px; top:25px;}
.openSider .header-top {left:110px; width:calc(100% - 140px);}

.header-toggle{ opacity:1; width:0; overflow:hidden;  background:#fff; border:0; padding:0; box-shadow:0 5px 20px 0 rgba(0,0,0,.1); border-radius:4px; margin:0; cursor:pointer; transition:ease-in-out .5s;}
.header-toggle button{ border:0; background:transparent; padding:14px 15px 15px;}
.header-toggle button svg path{ fill:rgb(73, 77, 75); }
.header-toggle button:hover svg path{ fill:#ee8763;}
.openSider .header-toggle{ opacity:1; width: auto; margin: 0 25px 0 0; transition: ease-in-out .5s;}

.header-search .ant-input-affix-wrapper .ant-input { width:100%; min-height:100%; background:#fff; border:0; padding:12px 15px; height:auto; min-width:370px; box-shadow:0 5px 20px 0 rgba(0,0,0,.1); border-radius:4px; color:#626262; font-size:15px; font-style:initial; font-weight:300; line-height:20px; letter-spacing:.08em;}
.header-search .ant-input-search { width:100% !important;}
.header-search .ant-input-affix-wrapper .ant-input-suffix {right: 15px;}
.header-search .ant-input-affix-wrapper i{ font-size:20px;}
.header-search .ant-select-show-search{ width:100% !important;}
.header-search .ant-select-auto-complete.ant-select .ant-select-selection__rendered { line-height:44px;}
body .header-search .ant-select-selection{ padding:0 !important; box-shadow:none !important;}

.header-dropdown {margin:0 0 0 auto;z-index:9;}
.header-dropdown>a{ background:#fff; border:0; padding:12px 40px 12px 15px; box-shadow:0 5px 20px 0 rgba(0,0,0,.1); border-radius:4px; color:#666; font-size:14px; font-weight:400; letter-spacing:.04em; display:block; line-height:20px; position:relative; min-width:150px;}
.header-top .header-dropdown>a.ant-dropdown-trigger>i.anticon-down{ width:0; height:0; border-left:5px solid transparent; border-right:5px solid transparent; border-top:6px solid #494d4b; position:absolute; top:50%; right:14px; transform:translateY(-50%); bottom:auto; font-size:0;}
.header-dropdown a:hover{ color:#ee8763;}
.header-top .header-dropdown>a:hover.ant-dropdown-trigger>i.anticon-down{border-top: 6px solid #ee8763;}

/***** Left panel css *****/
.left-panel{ width:280px; background:#fff; box-shadow:0 0 20px 0 rgba(0,0,0,.2); display:block; padding:35px 15px; position:fixed; top:0; left:0; height:100%; transition:ease-in-out .5s; }
.openSider .left-panel{ left:-310px;}
.openSider .content-wrap { padding:90px 30px 0 110px;}

.left-panel>.d-flex{ margin:0 0 20px;}
.sidebar-logo{ margin:0 auto 0 0;}
.sidebar-logo a{ font-size:22px; color:#020202; font-weight:700; letter-spacing:.04em; line-height:22px; text-transform:uppercase;}
.sidebar-nav{ margin:0 0 0 auto;}
.sidebar-nav img{ height:18px;}

/***** sidebar Navigation *****/
.sidebar-nav aside.ant-layout-sider{ flex:0 0 100% !important; max-width:100% !important; min-width:100% !important; width:100% !important; background:transparent;}
.sidebar-nav .ant-menu-submenu-title{ background:#f6f6f6; border-radius:6px;}
.sidebar-nav .ant-menu-submenu-title,
.sidebar-nav .ant-menu-submenu-title span{ color:#626262; font-size:15px; letter-spacing:.04em; font-weight:500;}
.sidebar-nav .ant-menu-vertical > .ant-menu-item, .sidebar-nav .ant-menu-vertical-left > .ant-menu-item, .sidebar-nav .ant-menu-vertical-right > .ant-menu-item, 
.sidebar-nav .ant-menu-inline > .ant-menu-item, .sidebar-nav .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, 
.sidebar-nav .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .sidebar-nav .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, 
.sidebar-nav .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title { height:54px; line-height:54px;}

.sidebar-nav .ant-menu-item > a {color: #626262;} 
.sidebar-nav .ant-menu-item > a:hover{color:#ee8763;}

.sidebar-nav .ant-menu-inline, 
.sidebar-nav .ant-menu-vertical, 
.sidebar-nav .ant-menu-vertical-left { border-right:0;}

.sidebar-nav .ant-menu-sub.ant-menu-inline > .ant-menu-item, 
.sidebar-nav .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{ height:44px; line-height:44px; margin:0;}

.sidebar-nav ul li.ant-menu-item:hover,
.sidebar-nav ul li.ant-menu-item.ant-menu-item-selected{ background-color:transparent; color:#626262;}
.sidebar-nav ul li.ant-menu-item.ant-menu-item-selected:after{ border:0; background:url('/images/left-arrow.svg') no-repeat 0 0; width:6px; height:11px; display:block; left:25px; top: 50%; transform: translateY(-50%);}
/* .sidebar-nav ul li.ant-menu-item:after{ content:''; background:url('/images/left-arrow-dark.svg') no-repeat 0 0; left:25px; top: 50%; transform: translateY(-50%); position:absolute; width:6px; height:11px; display:block;    
  right:auto; opacity: 1; border: 0;}
.sidebar-nav ul li.ant-menu-item:hover:after{background:url('/images/left-arrow.svg') no-repeat 0 0;} */

.ant-menu-item > a { display:block; color:#fff; font-weight:500; font-size:15px; letter-spacing:.04em;}

.sidebar-nav .ant-menu-item:hover, .sidebar-nav .ant-menu-item-active, .sidebar-nav .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.sidebar-nav .ant-menu-submenu-active, .sidebar-nav .ant-menu-submenu-title:hover { color:#ee8763;}
.sidebar-nav .ant-menu-item-selected > a, .sidebar-nav .ant-menu-item-selected > a:hover {color: #626262;}
.sidebar-nav .ant-menu-item-selected > a:hover {color: #ee8763;}

body .ant-menu-item:hover, body .ant-menu-item-active, body .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
body .ant-menu-submenu-active, body .ant-menu-submenu-title:hover { color:#ee8763;}

.sidebar-nav .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar-nav .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar-nav .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar-nav .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar-nav .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar-nav .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar-nav .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar-nav .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before { background:-webkit-gradient(linear, left top, right top, from(#626262), to(#626262)); background:linear-gradient(to right, #626262, #626262);}

/***** Bredcrumb  *****/
.bredcrumb-div { margin:0 0 10px;}
.page-title{ display:block; margin:0 0 15px;align-items:center;}
.page-title h2{ margin:0; padding:0; font-size:24px; color:#494d4b; font-weight:700; letter-spacing:.04em; text-transform:uppercase;line-height: 42px;}
.page-title button{ margin:0 0 0 auto; }
button.primary-btn{ border-radius:4px; background:#494d4b; border:2px solid #494d4b; line-height:18px; color:#fff; font-size:14px; letter-spacing:.04em; font-weight:600; padding:10px 15px; height:auto;}
body button.primary-btn:hover{ background:transparent; color:#494d4b; text-decoration:none; border:2px solid #494d4b;}

.white-box{ width:100%; padding:40px 40px 30px; background:#fff; box-shadow:0 0 20px 0 rgba(0,0,0,.1); border-radius:4px; margin-bottom:30px; display:inline-block; vertical-align:top;}

/***** pattern list *****/
.pattern-list{ display:block; width:calc(100% + 40px); margin:-30px -20px 20px;}
.pattern-list ul.slick-dots { margin:0; padding:0; list-style:none; flex-wrap:wrap; bottom:-10px;}
.pattern-list-box{ display:block; width:100%; padding:25px; background:#fff; box-shadow:0 0 20px 0 rgba(0,0,0,.1); border-radius:4px; margin:0 20px 0 0; position:relative;}
.pattern-list-img{ width:100%; margin:0 0 20px; height:250px;}
.pattern-list-img img{ width:100%; height:100%;}
.pattern-list-content{ width:100%; display:block; text-align:left;}
.pattern-list-content h2{ width:100%; padding:0; display:inline-block; font-size:22px; line-height:100%; margin:0 0 12px; vertical-align:top; text-overflow:ellipsis; white-space:nowrap;
height: 28px; overflow: hidden; color:#ee8763;}
.pattern-list-content h2 a{font-size:22px; color:#ee8763; font-weight:600; text-decoration:none; line-height:100%; letter-spacing:.05em;}
.pattern-list-content h2 a:hover{ color:#181818;}
.author-price{ position:relative; padding:0 100px 15px 0 ; min-height:35px;}
.author-price h5{ margin:0; padding:0; font-size:14px; line-height:20px; color:#606060;}
.author-price h6{ position:absolute; top:0; right:0; font-size:100%; line-height:100%;}
.author-price h6 span{font-size:14px; color:#606060; line-height:20px; font-weight:400;}
.author-price h6 strong{ font-weight:500; color:#333; font-size:18px;}
.purchase{ display:block; margin:0 0 20px;}
.purchase h4{ margin:0; padding:0; color:#606060; font-size:14px; line-height:20px;}
.purchase span{ display:block; background:url('/images/cart-icon.jpg') no-repeat 0 0; line-height:26px; padding:0 0 0 32px; color:#333; font-size:18px; font-weight:400;}
.difficulty{ display:block;}
.difficulty h4{ margin:0 0 6px; padding:0; font-size:14px; color:#181818; font-weight:400; line-height:100%; font-weight:500;}
.difficulty>.d-flex span{ margin:0 5px 0 0; color:#717c81; font-size:14px; line-height:24px; font-weight:500; }
.difficulty>.d-flex>ul{ margin:0; padding:0; list-style:none; }
.difficulty>.d-flex>ul>li{ width:56px; height:24px; border:1px solid #f1c4ab; background:#fff; display:block; margin:0 3px 0 0;}
.difficulty>.d-flex ul li:first-child{ border-radius:100px 0 0 100px; }
.difficulty>.d-flex ul li:last-child{ border-radius:0 100px 100px 0; }
.difficulty>.d-flex ul li.fill-bg{ background:#f1c4ab;}
.slick-slide>div>div{ padding:20px;}

.pattern-action{ opacity:0; overflow:hidden; width:100%; height:100%; top:0; left:0; position:absolute;}
.pattern-action:before{ content:''; width:100%; height:100%;  display:block; border-radius:4px; position:absolute; top:0; left:0;}
/* .pattern-action:after{ content:''; background:#ee8763; width:100%; height:12px;  display:block; border-radius:4px 4px 0 0; position:absolute; top:0; left:0;} */
.pattern-list-box:hover .pattern-action{  width:100%; height:100%; opacity:1;}
.pattern-action ul{ margin:0; padding:0; list-style:none; z-index:1; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%);}
.pattern-action ul li{ display:block; margin:10px 0;}
.pattern-action ul li button{ background:#fff; border-radius:4px; display:flex; width:155px; margin:0 auto; align-items:center; padding:10px 15px 11px 20px; transition:none; height:auto; border:0; box-shadow:0 0 20px 0 rgba(0,0,0,.2); }
.pattern-action ul li button:hover{ background:#ee8763; color:#fff; }
.pattern-action ul li button:hover span{ color:#fff;}
.pattern-action ul li button i{ width:30px; text-align:left; vertical-align:top;}
.pattern-action ul li button i img{ max-height:20px; }
.pattern-action ul li button span{ color:#666; font-size:14px; text-align:left; font-weight:600;}
.pattern-action ul li.standout-btn button{background:#494d4b; color:#fff;}
.pattern-action ul li.standout-btn button span{color:#fff;}
.pattern-list .ant-carousel .slick-dots li{ margin:0 4px;}
.pattern-list .ant-carousel .slick-dots li button {display: block; width:14px; height:14px; padding: 0; color: transparent;  font-size: 0; border: 1px solid #dadada; background: #fff; outline: none; cursor: pointer; opacity: 1; transition: all 0.5s; border-radius: 100px;}
.pattern-list .ant-carousel .slick-dots li.slick-active button{ background:#ee8763; width:14px; height:14px; border-color:#ee8763;}

/***** Add Pattern *****/
.white-bg-box{ background:#fff; padding:25px; box-shadow:0 5px 20px 0 rgba(0,0,0,.1);}
.filled-ul{ margin:0; padding:0; list-style:none;}
.filled-ul li{ width:100%; display:block; position:relative;}
.filled-ul li.half-width{ width:49%;}
.picture-card .ant-form-item .ant-upload-picture-card-wrapper{display:flex; flex-direction: column-reverse;}
.picture-card .ant-form-item .ant-upload-select-picture-card{width:45px; display: block; margin:0; background: transparent; border-radius: 4px; box-shadow:none !important; border:0; padding:0 !important; line-height:24px; font-size:14px; color:#999; font-weight:500; height:45px; font-family:'Raleway'; letter-spacing:.04em; position:relative;}
.picture-card .ant-row.ant-form-item>.ant-col.ant-form-item-label label{ margin:0; line-height:16px; font-weight:500;}
.picture-card .ant-form-item .ant-upload-select-picture-card>span{width:100%; display:block; padding:0;}
.picture-card .ant-form-item .ant-upload-select-picture-card>span>div{ position:absolute; top:0; right:0; width:45px;  height:45px; display:block; margin:0;  background:#ee8763; border-radius:4px; box-shadow:2px 4px 4px 0 rgba(0,0,0,.1) !important; border:1px solid #e67750;
padding:10px 0 !important; line-height:24px; font-size:20px; color:#fff; font-weight:500; font-family:'Raleway'; letter-spacing:.04em; text-align:center;} 
.picture-card .ant-upload-list-picture-card{ margin-top:15px;}
.picture-card .ant-form-item .ant-upload-text{ display:none; }
.picture-card .ant-form-item .ant-col.ant-form-item-label{ position:absolute; top:45px; left:15px; letter-spacing:.08em;}
.picture-card .ant-form-item .ant-upload.ant-upload-select-picture-card:hover{ border-color:#ee8763 !important;}
.picture-card .ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {position: absolute;bottom: 7px;display: block;font-size: 12px;}
.picture-card .ant-upload-list-picture-card .ant-upload-list-item { width:90px; height:90px; margin:0 8px 8px 0;}
.picture-card .ant-form-item .ant-upload-list-picture .ant-upload-list-item-error,
.picture-card .ant-form-item .ant-upload-list-picture-card .ant-upload-list-item-error { border-color:#dedcdc;}
.picture-card .ant-form-item .ant-upload-list-item-actions>a{ display:none;}
.picture-card .ant-form-item .ant-upload-list-picture-card .ant-upload-list-item-actions{ opacity:1; width:20px; height:20px; background: #fff; border: 1px solid #dedcdc; display: block; border-radius: 100px; position: absolute; top: -8px; right: -8px; left: auto; transform: initial;}
.picture-card .ant-form-item .ant-upload-list-picture-card .ant-upload-list-item-actions i svg{ display:none;}
.picture-card .ant-form-item .ant-upload-list-picture-card .ant-upload-list-item-actions i{ background:url(/images/close.svg) no-repeat 0 0; height:10px; width:10px; position:relative; top:-2px; left:1px; opacity:.5;}
.filled-ul li.price-input{ width:150px;}

.filled-ul li.is-active .ant-form-item-label{ display:none;}
body .ant-form-item .ant-switch {margin:0;vertical-align:top;}
body .ant-form-item .ant-switch-checked{ background:#ee8763;}

.difficulty-level-box .ant-form-item-control {line-height:100%;}
.difficulty-level-box span{margin:0 0 4px 6px ; color:#717c81; font-size:14px; line-height:100%; font-weight:500; display:block; text-transform:capitalize;}
.difficulty-level-box ul{margin:0; padding:0; list-style: none;display:inline-block;vertical-align:top;}
.difficulty-level-box ul li{ width:56px; margin:0 4px 0 0; height:24px; vertical-align:top; display:inline-block; }
.difficulty-level-box ul li:first-child button{ border-radius:100px 0 0 100px;}
.difficulty-level-box ul li:last-child button{ border-radius:0 100px 100px 0;}
.difficulty-level-box ul li.filld-button button:hover,
.difficulty-level-box ul li.filld-button button{ background:#f1c4ab;}
.difficulty-level-box ul li button{width:100%; height:100%; border: 1px solid #f1c4ab; background: #fff; display: block; margin: 0 3px 0 0; border-radius:0;}
.difficulty-level-box ul li button:hover{ background:#fff; border:1px solid #f1c4ab;} 

.btm-btn{ background:#f5f5f5; padding:30px;}
.filled-ul li.btm-btn {background:#f5f5f5; padding:30px; margin:0;}
.filled-ul li.btm-btn .ant-row.ant-form-item{ margin:0; text-align:center;}
.btm-btn .ant-form-item-control button{ background:#fff; border:2px solid #fff; border-radius:4px; display:inline-block; vertical-align:top; color:#666; text-transform:uppercase; letter-spacing:.06em; padding: 9px 25px; height:auto; margin:0 10px; font-weight:500;}
.btm-btn .ant-form-item-control button:hover{ background:transparent; border:2px solid #fff; color:#666; }
.btm-btn .ant-form-item-control button + button{ background:#ee8763; border-color:#ee8763; color:#fff;}
.btm-btn .ant-form-item-control button + button:hover{ background:transparent; color:#ee8763; border-color:#ee8763;}
.ant-carousel .slick-slide {text-align: center; overflow: hidden;}
.ant-tooltip {display: none;}

.price-input .ant-input-number{ border:0; box-shadow:none; height:auto;}
.price-input .ant-input-number:hover {border: 0px !important; }
.price-input .ant-input-number-handler-wrap,
.price-input .ant-input-number:hover .ant-input-number-handler-wrap{ opacity:1;}
.price-input .ant-input-number-handler-wrap {top: 1px; right: 1px; width: 22px; height: calc(100% - 2px); border-left:1px solid #dedcdc;}
.price-input .ant-input-number-handler-down:hover, 
.price-input .ant-input-number-handler-up:hover {height: 50% !important;}
.price-input .ant-input-number-handler-wrap:hover .ant-input-number-handler {  height: 50%;}
.smallview-toggle{ display:none;}

.inner-ul{ margin:0; padding:0; list-style:none; flex-wrap:wrap;}
.inner-ul li{ width:49%}
.inner-ul li:nth-child(2n+2){ margin-left:auto;}
.yarn-filled li .ant-row.ant-form-item { margin:0 0 15px;}

.suggested-needles{ margin-bottom:15px;}
.suggested-needles .three-li{ margin:0; padding:0; list-style:none; flex-wrap:wrap;    justify-content: space-between;}
.suggested-needles .three-li li{ width:32%; margin:0;}
.suggested-needles .ant-select-selection--single{ border-radius:4px; box-shadow:2px 4px 4px 0 rgba(0,0,0,.1) !important; width:100%; border:1px solid #dedcdc; padding:7px 5px !important;
line-height: 24px; font-size: 14px; color: #999; font-weight: 500; height: auto; font-family: 'Raleway'; letter-spacing:.04em;}
.suggested-needles .ant-select-focused .ant-select-selection, .suggested-needles .ant-select-selection:focus, .suggested-needles .ant-select-selection:active,
.suggested-needles .ant-select-selection:hover{ border-color:#ee8763;}
.suggested-needles .add-another{ margin-top:-15px;}
body .ant-form-item-control { line-height:100%;}

.add-another button{ border:0; background:transparent; vertical-align:top;box-shadow: none; padding:0;}
.add-another button i{ background:#ee8763; width:24px; height:24px; border-radius:100px; margin:0 2px 0 0;vertical-align:middle; color:#fff; line-height:28px !important; font-size:14px; vertical-align:top;}
.add-another button span{ color:#666; font-size:14px; letter-spacing:.04em; font-family:'Raleway'; line-height:24px; font-weight:600;}
.add-another button:hover span{ color:#ee8763;}

/* .another-label li .add-another{ margin:38px 0 0;  } */
.page-content {margin-top:35px;}

.double-input>label{ width:100%;}
.double-input{ flex-wrap:wrap;}
.double-input .ant-form-item{ width:49%;}
.double-input .ant-form-item + .ant-form-item{ margin-left:auto; }

.has-error .ant-form-explain, 
.has-error .ant-form-split {margin:2px 0 0 4px;}
.ant-form-explain, 
.ant-form-extra {font-size: 13px;}

.size-list-box{ min-height:434px; height:100%;}
.size-info-head{ flex-wrap:wrap; margin:0;}
.size-info-head h3{ margin:0 0 15px; padding:0; display:block; font-size:15px; width:100%; line-height:1.5rem; word-break:break-all;}
.size-info-head h3 span{color:#606060; font-weight:700; font-size:14px;}
.size-info-head h3 big{color:#333; font-weight:400; font-size:15px;}
.size-info-head h3.full-width{ width:100%;}
.size-list h2{ margin:0 0 45px; padding:0; display:block; text-align:center; font-size:24px; color:#ee8763; line-height:100%;}
.size-info-box + .size-info-box{ margin-top:20px;}
.size-info-box h4{ margin:0; padding:0; display:block; font-weight: 700; font-size: 15px; line-height: normal;  color:#606060;}
.size-info-box p{ margin:0; padding:0; display:block; font-size:14px; color:#606060; font-weight:400; line-height:24px;}
.fullpage-wrap{ padding:30px;}
.fullpage-wrap .white-box {padding: 30px;}
.viewsize-content .ant-descriptions + .ant-descriptions{ margin-top:30px;}
.viewsize-content .ant-descriptions-item-label{ width:26%;}
.viewsize-content .ant-descriptions-title{ margin-bottom:8px; }

.CustomeSelect .ant-select .ant-select-selection--single:focus {box-shadow: none;}
.CustomeSelect .ant-select {border-radius: 4px;box-shadow: 2px 4px 4px 0 rgba(0,0,0,.1) !important;width: 100%;border: 1px solid #dedcdc;padding: 6px 15px !important;line-height: 24px;font-size: 14px;color: #999;font-weight: 500;height: auto;font-family: 'Raleway';letter-spacing: .04em;outline: 0; background:#fff;}
.CustomeSelect .ant-select .ant-select-selection--single {border: 0;}
.CustomeSelect .ant-select-arrow{ right:0;}

.neddles_li li {padding: 0 15px; width:33.33%;}
.neddles_li {margin: 0 -15px;}
i.anticon-minus-circle-o svg path:last-child {display: none;}
i.anticon-minus-circle-o {background: #ee8763;min-width:24px; width: 24px;height: 24px;border-radius: 100px;margin: 0 2px 0 0;vertical-align: middle;color: #fff;line-height: 28px !important;font-size: 14px;vertical-align: top;}

.pattern_wrapper>div>div { padding:0 10px; margin-bottom:30px; flex:0 0 33.33%; -ms-flex:0 0 33.33%; max-width:33.33%;}
.pattern_wrapper>div {display: flex;flex-wrap: wrap;margin: 30px 0 0 0;}
.pattern_wrapper {overflow: hidden;}
.pattern_img_cstm {margin-bottom: 20px;}
.pattern_img_cstm img {width: 200px;height: 200px;margin-right: 10px;}

.multicopy-li li{ position:relative; margin-bottom:30px; width:100%;}
.multicopy-li .ant-form-item { width:calc(25% - 15px); margin:0; position:relative;}
.multicopy-li .ant-form-item + .ant-form-item{ margin-left:20px;}
.multicopy-li i.anticon-minus-circle-o{ position:absolute; bottom:8px; right:48%;}

.neddles_li  + i.dynamic-delete-button{ position:absolute; top:0; right:0;}
.upload-images{ flex-wrap:wrap;}
.upload-images.d-flex>div{ border:1px solid #eee; padding:6px; box-shadow:0 0 20px 0 rgba(0,0,0,.1); border-radius:4px; position:relative;  margin-bottom:8px; margin:0 6px 6px 0;}
.upload-images.d-flex>div img {width:80px;height:80px;margin-right:0;}
.upload-images.d-flex>div button{ position:absolute; top:-5px; right:-5px; width:20px; height:20px; border-radius:100px; padding:0;}  
.upload-images.d-flex>div button i svg { display:inline-block; vertical-align: top; line-height: 20px; margin: 4px 0 0; width: 10px;height: 10px;}
.upload-images.d-flex>div button i{  line-height:20px; vertical-align:top; width:18px; height:18px; font-size:100%;}
.upload-images.d-flex>div button:hover, .upload-images>div button:focus {color:#ee8763; background-color:#fff; border-color:#ee8763;}
.add-another-needle{ margin:-20px 0 30px;}

.white-box.emply-data-message{ flex: 0 0 calc(100% - 40px); -ms-flex: 0 0 calc(100% - 40px); max-width: calc(100% - 40px); margin: 0 auto;}
.emply-data-message p{    padding: 50px 0; text-align: center; display: block; width: 100%; font-size: 18px; line-height: 26px; letter-spacing: 0; font-weight: 500; max-width: 500px; margin: 0 auto; color:#666;}
.emply-data-message p span{ color:#ee8763; }

.suggested-needles-list {margin-top:10px;}
.suggested-needles-list ul{ margin:0; padding:0; list-style: none; flex-wrap: wrap;}
.suggested-needles-list ul li{ width:100%; flex-wrap: wrap; border: 1px solid #eee; margin: 0 0 10px;}
.suggested-needles-list ul li h4 {margin: 0;width: 100%;}
.suggested-needles-list h4 span { background: #eee; display: block; font-size: 12px; padding: 7px 10px; width: 42%; font-weight: 600;}
.suggested-needles-list h4 big{ font-size:13px; background: #fff; display: block; padding: 7px 10px; font-weight: 400;}
td.ant-descriptions-item-content{ line-height:1.6rem;}

.header-search .ant-select-search__field__wrap:after{  content:''; transform:translateY(-50%);  position:Absolute; top:50%; right:15px; background:url(/images/search-icon.svg) no-repeat 0 0 / 18px  18px; display:block; width:18px; height:18px;}
.header-search .ant-select-search__field__wrap{background: #fff; border: 0; padding: 6px 40px 6px 15px; box-shadow: 0 5px 20px 0 rgba(0,0,0,.1); border-radius: 4px; color: #666; font-size: 14px; font-weight: 400; letter-spacing: .04em; display: block; line-height: 20px; position: relative;  min-width: 300px;}
.header-search .ant-select-search__field__wrap input{border:0;}
.header-search .ant-select-selection__placeholder,
.header-search .ant-select-search__field__placeholder{ margin:0; top:12px; right:auto; left:2px; z-index:1;}
.header-search .ant-select-auto-complete.ant-select .ant-input{ padding:0;}

.header-search .ant-select-auto-complete.ant-select .ant-input:focus,
.header-search .ant-select-auto-complete.ant-select .ant-input:hover { border-color:transparent; border-right-width:0 !important; box-shadow:none;}

div.yarn-filled>li { display:flex;}
div.yarn-filled>li + li {margin-top:30px;}
.yarn-filled + i.dynamic-delete-button{ position:relative; top:9px; right:0; margin-left:10px;}
.yarn-filled ul.inner-ul{ width:100%;}


.ant-modal-wrap  .ant-modal-content{ border-radius:0; border-top:6px solid #ee8763;}
.ant-modal-wrap  button.ant-modal-close{width: 30px; height: 30px; background: #fff; border-radius: 100px;  display: block; box-shadow: 0 0 30px 0 rgba(0,0,0,.2); top:-20px; right:-10px;}
.ant-modal-wrap  .ant-modal-close-x { display:block; width: 100%; height: 100%; font-size: 14px; line-height: 30px; color:#333; text-align:center;
padding:0 0 0 1px;}
.two-column .ant-form-item{ width:45%;}
.two-column .ant-form-item + .ant-form-item{ margin-left:auto;} 

.construcation .ant-radio-group label{ margin:0 15px 0 0;}
.construcation label span{ color:#999; letter-spacing:0;}
.popup-btn { text-align:center;}

button.WhiteButton{background:#fff; border: 2px solid #dedcdc; border-radius: 4px; display: inline-block; vertical-align: top; color: #666; text-transform: uppercase;
letter-spacing: .06em; padding: 9px 25px; height: auto; margin: 0 10px; font-weight: 500; min-width: 114px;}
button.OrangeButton{ background:#ee8763; border:2px solid #ee8763; color:#fff; min-width:100px; padding:9px 25px; height: auto;
text-transform:uppercase; font-weight:600; min-width: 114px;}

.popup-btn .ant-btn-primary:active, .popup-btn .ant-btn-primary.active,
.popup-btn button:hover{ background:transparent; color:#ee8763; border-color:#ee8763;}
.ant-modal-body + .ant-modal-footer{ display:none;}
body .ant-radio-checked .ant-radio-inner {border-color:#ee8763;}
body .ant-radio-inner::after {background-color: #ee8763;}
body .ant-radio-wrapper:hover .ant-radio, body .ant-radio:hover .ant-radio-inner, body .ant-radio-input:focus + .ant-radio-inner {border-color: #ee8763;}
.ant-modal{ min-width:400px; max-width:400px;}

.grid-wrap {margin:15px 0 0; }

.grid-wrap .grid-left .grid-box-wrap.repeat-div .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {border-color: #1890ff !important;}
/* .grid-left{ max-width:270px;} */
/* .grid-left{ max-width:460px;} */
.grid-box-wrap{ margin:0;}
.grid-box-wrap h2{ margin:0; padding:0 0 12px; display:block; color:#494d4b; font-size:18px; font-weight:600;}
.grid-box{ background:#fff; border-radius:4px; box-shadow:0 0 20px 0 rgba(0,0,0,.1);}
.grid-box ul{ margin:0; padding:10px; flex-wrap:wrap; list-style:none;  border-radius:4px;}
.grid-box ul li{ width:45px; height:45px; text-align:center; padding:0; border:0; margin:4px;}
.grid-left .ant-checkbox-checked .ant-checkbox-inner::after{ display:none;}

.grid-box.compound-grid ul li{ width:auto;  padding:0; display:flex; align-items:center; margin:4px; height:auto;}
/* .grid-box.compound-grid ul li img{ max-width:210px;} */
.grid-box.compound-grid ul li img{max-width:initial;}

/* .grid-right{ width:calc(100% - 320px); margin:0 0 0 auto;} */
/* .grid-right {width:calc(100% - 500px); margin:0 0 0 auto;} */

.grid-right-title{ align-items:center; margin:0 0 15px;}
.grid-right-title h2{ margin:0; padding:0; display:block; color:#494d4b; font-size:18px; font-weight:600;}
.grid-right-box{background:#fff; border-radius:4px;box-shadow:0 0 20px 0 rgba(0,0,0,.1); padding:20px; width:100%; display:flex; flex-direction:column;}

.grid-right-head{ background:rgba(173, 166, 166, 0.2); align-items:center; margin:0;}
.grid-right-head-left strong{ color:#999; font-size:16px; line-height:22px; font-weight:400; margin-right:30px;}
.grid-right-head-left strong span{color:#e7ba62; font-weight:700;}
.grid-right-head-right{ margin-left:auto; align-items:center;}
.grid-edit button:hover, .grid-edit button:focus, .grid-edit button{ background:transparent; border:0; box-shadow:none; padding:0;}

.gridDelete button:hover, .gridDelete button:focus, .gridDelete button{ background:transparent; border:0; box-shadow:none; padding:0;}
.gridDelete button{ margin:0 10px 0 0;}
.grid-edit button span,
.gridDelete button span{ font-size:16px; color:#666; font-weight:500; margin:0 5px;}
.grid-edit img,
.gridDelete img{ max-width:20px; vertical-align:top;}

.grid-nav{ background:rgba(215,215,215,.6); margin:0 0 0 30px; height:50px; width:auto; display:flex; 
align-items:center; justify-content:center; padding-left:5px; font-weight:500;} 
.grid-nav button:focus, .grid-nav button:hover,
.grid-nav button{ background:transparent; border:0; box-shadow: none;}
.grid-nav span{ margin-right:15px;font-size: 16px;color: #666;font-weight: 500;}

.grid-right-head-left {padding-left: 20px;}
.ant-popover-message>i.anticon-exclamation-circle{ display:none;}
.ant-popover-message .ant-popover-message-title{ padding-left:0 ;}
.ant-btn-primary {background-color: #ee8763 !important;border-color: #ee8763 !important; color:#fff !important;}
.ant-btn-primary:hover {background-color:transparent !important;border-color: #fff !important; color:#666 !important;}

.suggested-needles-box {padding:30px 0 0; display:flex; width:100%; flex-wrap:wrap;}
.suggested-needles-box>.d-flex{ width:100%;}
ul li div.suggested-needles-box label{ position:absolute; top:0; left:0;}
.suggested-needles-box .neddles_li {margin:0 -15px; width:calc(100% + 30px); flex-wrap:wrap;}
.neddles_li + i.dynamic-delete-button{ position:relative; top:9px; margin-left:15px; min-width:24px;}
.suggested-needles-box + .suggested-needles-box{ padding-top:0;}
.add-another .ant-row.ant-form-item{ margin:-20px 0 0;}
.yarn-filled .add-another .ant-row.ant-form-item{ margin:0;}
.edit-on-click{ position:absolute; top:0; width:100%; height:100%;}
.pattern-action .edit-on-click button{ font-size:0; background:transparent; border:0; width:100%; height:100%;}

.grid-right-grid .ant-checkbox-wrapper,
.grid-box ul li .ant-checkbox-wrapper{ position:relative; width:100%; height:100%; display:block; margin:0 auto;}

.grid-right-grid .ant-checkbox,
.grid-box ul li .ant-checkbox{width: 100%; height:100%; position:absolute; top:0; left:0; z-index:0;}

.grid-right-grid .ant-checkbox-inner,
.grid-box ul li .ant-checkbox-inner{ width: 100%; height:100%; border:2px solid #d7d7d7; border-radius:0;}

.grid-box ul li .ant-checkbox-checked .ant-checkbox-inner {background-color:transparent;
/* border:2.75px solid #1890ff; margin:-1.75px; width:calc(100% + 2.75px); height:calc(100% + 2.75px);*/}

.grid-right-grid .ant-checkbox + span,
.grid-box ul li .ant-checkbox + span {z-index: 1;position:relative; padding:0; display:flex; justify-content:center; height:100%; align-items:center;}

.grid-right-grid .ant-checkbox-checked .ant-checkbox-inner{ border-color:transparent;background-color:transparent;}
.grid-right-grid table { border-collapse:collapse; border:1px solid #d7d7d7;}
.grid-right-grid table td{ background:#fff;}

.grid-right .grid-right-grid .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.grid-right .grid-right-grid .ant-checkbox:hover .ant-checkbox-inner, 
.grid-right .grid-right-grid .ant-checkbox-checked::after,
.grid-right .grid-right-grid .ant-checkbox-input:focus + .ant-checkbox-inner{ border-color:#d7d7d7;}

.grid-right .grid-right-grid .ant-checkbox-wrapper{ width:100%; height:100%; border:1px solid #d7d7d7; outline:1px solid #d7d7d7;}
.grid-table-row {position:relative;}
.grid-table-row:nth-child(1) ul>div{ position:relative;}
.grid-table-row:nth-child(1) ul>div>span{position:absolute; top:-28px; left:50%; transform:translateX(-50%); display:block;}
.grid-table-row>ul>div>span{ display:none;}

.grid-table tr td {height:50px; width:50px;}
.grid-table ul{ margin:0; padding:0; list-style:none; display:flex;}
.grid-table ul li{ width:43px; height:43px;}
.grid-table ul li.AddedStitches{ background:#fff; position:relative;}
.grid-table ul li.AddedStitches .ant-checkbox + span >div{background-color:#fff;}
/* .grid-box ul li span img{ max-width:34px; max-height:34px;} */
.grid-box ul li span img{ max-width:initial; max-height:initial;}

.grid-right-grid .ant-checkbox + span{ display:inline-flex; min-width:41px;}
.grid-right-grid .ant-checkbox + span > div{ width:100%; height:100%;}
.defaultColor { background-color:#fff;}
.grid-right .grid-right-grid .ant-checkbox-wrapper span img{ margin:0;}
.grid-right .grid-right-grid .ant-checkbox-inner{ border:0; border-radius:0;}

.color-wrap{ flex-wrap:wrap; margin:0; max-width:290px; width:100%;}
.color-div {margin:0 .6rem .6rem 0; position:relative;}
.color-div input[type="color"] { border: 0; background:transparent; width:100%; height:100%; padding: 0 !important; box-shadow: none !important;}
.color-div .ant-checkbox-wrapper{ position:relative; height:30px; width:56px; overflow:hidden; vertical-align:top;}
.color-div .ant-checkbox{ width:100%; height:100%;}
.color-div .ant-checkbox-inner{width:100%; height:100%;border:0; background:transparent;}
.color-div .ant-checkbox + span{ position:relative; top:0; left:0; height:100%;padding:0;}
.color-div .ant-checkbox {width:100%;height:100%;display: block;position: absolute;top:0;left:0;background: transparent;}
.color-div .ant-checkbox-checked::after { position: absolute; top:0; left:0; width:100%;  height:100%; border:2px solid #1890ff; border-radius: 2px; visibility: visible;    z-index: 1;}
.color-div .ant-checkbox-checked .ant-checkbox-inner { background-color: transparent; border-color: #1890ff;}
.color-div .colorSection {padding:0; display:block; width: 100%; height: 100%;}
li.ColorDiv .color-div .ant-checkbox-checked::after{ border-color:transparent;}

.add-color-popup .color-div {margin:0 2px 2px 0; height:30px; width:60px;}
.add-color-popup { position:relative; z-index:1;flex-wrap: wrap;} 
.add-color-popup label{ margin:0 10px 0 0;}
.add-color-popup svg{height:18px; fill: #999; vertical-align:middle;}
.add-color-popup button{ background:transparent; border:0;}
.add-color-popup .popover { position:absolute; top: 100%; left: 0; margin: 10px 0 0; }
.add-color-popup .color-wrap{ display:flex!important; margin:5px 0 0 0; width:100%; display:inline-block;vertical-align:top;}

.repeat-div .ant-checkbox:hover::after, .repeat-div .ant-checkbox-wrapper:hover .ant-checkbox::after{ visibility:hidden;}
.repeat-div .ant-checkbox input[value="left-border"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border: 2px dashed #d9d9d9; border:2px dashed #d9d9d9; border-left:2px solid #ee8763;}
.repeat-div .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="top-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="left-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="left-border"]:focus + .ant-checkbox-inner{border: 2px dashed #d9d9d9; border-left:2px solid #ee8763;}

.repeat-div .ant-checkbox input[value="TopBottomRepeat"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border: 2px dashed #d9d9d9; border:2px dashed #d9d9d9; border-bottom:2px solid #ee8763; border-top:2px solid #ee8763;}
.repeat-div .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="TopBottomRepeat"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="TopBottomRepeat"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="TopBottomRepeat"]:focus + .ant-checkbox-inner{border: 2px dashed #d9d9d9; border-top:2px solid #ee8763; border-bottom:2px solid #ee8763;}

.repeat-div .ant-checkbox input[value="right-border"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border: 2px dashed #d9d9d9; border:2px dashed #d9d9d9; border-right:2px solid #ee8763;}
.repeat-div .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="right-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="right-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="right-border"]:focus + .ant-checkbox-inner{border: 2px dashed #d9d9d9; border-right:2px solid #ee8763;}

.repeat-div .ant-checkbox input[value="bottom-border"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border: 2px dashed #d9d9d9; border:2px dashed #d9d9d9; border-bottom:2px solid #ee8763;}
.repeat-div .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="bottom-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="bottom-border"] + .ant-checkbox-inner, 
.repeat-div .ant-checkbox:hover input[value="bottom-border"]:focus + .ant-checkbox-inner{border: 2px dashed #d9d9d9; border-bottom:2px solid #ee8763;}

.chat-view-btm {margin:20px 0 0; justify-content:center;}
.chat-view-btm .ant-row.ant-form-item {margin:0; text-align: center; }

.instruction-div:after {content:''; background:#fff; position:absolute; right:0; top:-40px; width:80px; height:calc(100% + 44px); z-index:-1;}
.instruction-div:before {background: white;content: '';height:calc(100% + 44px); width:40px; right:0;position: absolute;top:-40px;display: block;z-index: -2;}
.instruction-div{ z-index:11; padding:0 11px; width:auto; position:-webkit-sticky; position:sticky; right:0; background:#fff; margin:0 0 0 auto; height:100%; min-width:40px;}
.instruction-div button.ant-btn{ padding:0; margin:0; background:url(/images/plus-round.svg) no-repeat 0 0; width:18px; height:18px; display:block; border:0;}
.instruction-div .ant-btn:hover, .instruction-div .ant-btn:focus, .instruction-div .ant-btn:active, .instruction-div .ant-btn.active{ background:url(/images/plus-round.svg) no-repeat 0 0;}
.instruction-div input{ border:1px solid #d7d7d7; padding:10px; line-height:20px; max-width:210px;}
.instruction-div>div {height:43px; display:flex; align-items:center; justify-content:flex-end;}
.instruction-div .grid-instruction-open{ min-width:180px;}

.instructionWidth .instruction-div:after{ width:250px;}
.instructionWidth .instruction-div:before{width:250px;}
.instructionWidth .instruction-div{min-width:210px;}
.instructionWidth .GridRightCounter {right:211px;}
.instructionWidth .GridRightCounterRightWrap{right:211px;}
.instructionWidth .BoxRightBorder:before{width:250px;}


.grid-table-div{ position:relative; margin:0;}
.instruction-open .grid-table-div{ padding-right:230px;}
.instruction-open input{ display:block;}
.instruction-open button.ant-btn{ display:none;}
::-webkit-color-swatch{ border-color:transparent;}

.grid-table .grid-table-div:last-child ul li{margin-bottom:0;}
.tools {text-align:center; margin:auto 0 0;}
.tools button{ width:26px; height:26px; display:inline-block; vertical-align:top; background-position:0 0; background-repeat:no-repeat; border:0; background-color: transparent; text-align:left;}
.tools button.zoom-minus{ background-image:url(../src/assests/minus.svg);}
.tools button.zoom-line{ background:#c7c7c7; height:6px; width:250px; margin:9px 15px;}
.tools button.zoom-line span{ background:#e7ba62; height:100%; position:relative; display:inline-block; vertical-align:top; width:29%;}
.tools button.zoom-line span:before{content:''; width:6px; height:24px; position: absolute; right:0; top:50%; transform:translateY(-50%); background:#e7ba62;}
.tools button.zoom-plus{ background-image:url(../src/assests/plus-circle.svg);}

.stitches-rotation{ margin:0 0 0 auto;}
.stitches-rotation button{ border:0; padding:13px 20px 12px; box-shadow:0 0 20px 0 rgba(0,0,0,.2);background: #fff; border-radius:4px; box-shadow: 0 0 20px 0 rgba(0,0,0,.1); color:#494d4b; font-size:16px; font-weight:600; text-transform:uppercase;}
.stitches-rotation button + button{ margin-left:10px;}
.stitches-rotation button:hover{ background:#ee8763; color:#fff;}

.important-div{ display:block; margin:0 0 20px;}
.important-div h2{ margin:0; padding:0 0 12px; display:block; color:#494d4b; font-size:18px; font-weight:600;}
.important-div i{background:url(../src/assests/exclamation.svg) no-repeat 0 0 / 3px 16px; width:3px; height:15px; display:block; margin:0 auto;}
.important-div .ant-checkbox-wrapper{ position:relative;}
.important-div .ant-checkbox-inner {background-color:transparent; border:3px dotted red; width:40px; height:40px;}
.important-div .ant-checkbox-checked .ant-checkbox-inner {background-color: transparent; border:3px solid #1890ff;}
.important-div .ant-checkbox-checked .ant-checkbox-inner::after{ display:none;}
.important-div .ant-checkbox-wrapper .ant-checkbox-checked + span i,
.important-div .ant-checkbox-wrapper:hover .ant-checkbox + span i{background:url(../src/assests/exclamation-blue.svg) no-repeat 0 0 / 3px 16px;}

.important-div .ant-checkbox-checked::after{ border:transparent;}
.important-div .ant-checkbox + span{position: absolute; padding: 0; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.important-div .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.important-div .ant-checkbox:hover .ant-checkbox-inner, 
.important-div .ant-checkbox-input:focus + .ant-checkbox-inner{border-color:#1890ff;}


.grid-right .grid-right-grid .ant-checkbox-wrapper span .important-border:after { content:''; min-width:41px; border:2px dotted red; height:100%; position:absolute; top:0; left:0; width:100%;}

.grid-right .grid-right-grid .grid-table img.TopBottomRepeat{border-top: 2px solid #1890ff  !important; }
.grid-right .grid-right-grid .grid-table img.right-border{border-right: 2px solid #1890ff !important; }
.grid-right .grid-right-grid .grid-table img.bottom-border{border-bottom: 2px solid #1890ff !important; }
.grid-right .grid-right-grid .grid-table img.left-border{border-left: 2px solid #1890ff !important;}

body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomRepeat:before{  content:''; background:#ee8763; position:absolute; top:0; left:0; width:100%; height:2px; display:block; z-index:2; border:0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomRepeat:after{  content:''; background:#ee8763; position:absolute; bottom:0; left:0; width:100%; height:2px; display:block; z-index:2; border:0;}

body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomMarker:before{  content:''; background:#000; position:absolute; top:0; left:0; width:100%; height:3px; display:block; z-index:2; border:0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomMarker:after{  content:''; background:#000; position:absolute; bottom:0; left:0; width:100%; height:3px; display:block; z-index:2; border:0;}

body .grid-right .grid-right-grid .ant-checkbox-wrapper span .right-border:before{  content:''; background:#ee8763; position:absolute; top:0; right:0; width:2px; height:100%; display:block; z-index:2; border:0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .bottom-border:before{  content:''; background:#ee8763; position:absolute; bottom:0; left:0; width:100%; height:2px; display:block; z-index:2; border:0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .left-border::before{ content:''; background:#ee8763; position:absolute; top:0; left:0; width:2px; height:100%; display:block; z-index:2; border:0;}

div.ant-breadcrumb a:hover{color:#ee8763;}
input[type='radio'], input[type='checkbox']{ display:none;}
.GridRightCounter{ z-index:12; padding:0 11px; margin:0; width:40px; text-align:center; position:sticky; right:40px; position:-webkit-sticky; background:#fff;}
.GridRightCounter-row{ width:100%; line-height:43px; display:block; height:43px; position:relative;}
.GridRightCounter-row button{border:0;}

.grid-right-grid:before { content:''; width:100%; background:white; height:40px; position:sticky; top:0; left:0; display:block; z-index:2;}
.grid-right-grid{ overflow:auto; height:calc(100vh - 230px); padding:0; position:relative; display:flex; flex-direction:row; flex-wrap:wrap; margin:0 0 20px;}

.GridRightCounterLeftWrap{position:sticky; left:0; width:43px; text-align:center; line-height:43px;height:100%; z-index:12; background:#fff;}
.GridRightCounterLeftWrap:before {content: '';background: #fff;height: calc(100% + 78px);width: 100%;position: absolute; top:-40px;left: 0;z-index:0;}
.GridRightCounterLeftWrap .GridRightCounter-left{ height:86px; padding:0 0 43px; line-height:43px; width:43px; position:relative;}
.GridRightCounterLeftWrap .GridRightCounter-left button{ border:0; padding:0 13px;}

.odd.GridRightCounterLeftWrap .GridRightCounter-left{ padding:43px 0 0;}
.odd.GridRightCounterLeftWrap{padding-bottom:47px;}

.GridRightCounterRightWrap{position:sticky; right:40px; width:40px; text-align:center; line-height:43px; margin:0; z-index:12; height:100%;}
.GridRightCounterRightWrap .GridRightCounter-right{ height:86px; line-height:43px; width:43px; background:#fff; padding-bottom:43px; position:relative;}
.GridRightCounterRightWrap .GridRightCounter-right button{ border:0;}
.GridRightCounterRightWrap .GridRightCounter-right:last-child{ padding-bottom:0; height:43px;}
.even.GridRightCounterRightWrap{padding-bottom:45px;}

/* .even.GridRightCounterLeftWrap .GridRightCounter-left {padding: 0 0 43px;} */
.even.GridRightCounterRightWrap .GridRightCounter-right { padding:43px 0 0;}
 
.colorSection{padding:4px 40px 7px 17px;}
.grid-colors .popover{ position:absolute; top:100%; left:-89px; margin:10px 0 0; z-index:2;}
.grid-colors h2 button{ border:0; display:inline-block; vertical-align:middle; margin:1px 0 0 20px; position:relative; background:transparent;}
.grid-colors h2 button svg{ height:20px;}
.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active { background:initial;}
.grid-right-grid .ant-checkbox + span .triangle:before {content:''; width:0; height:0; border-top:0 solid transparent; border-left:15px solid red; border-bottom:15px solid transparent; position:absolute; left:0; top:0; z-index:12;}
.ant-form label.SelectColor {color: red; width: 100%; font-size: .7rem; }

.TopHotizontNumber { position:-webkit-sticky; position:sticky; top:0; width:40px; height:43px; text-align:center; line-height:43px; margin:0 0 0 1px; z-index:10;}
.TopHotizontNumber>div{ width:41px; height:42px; line-height:42px;}
.ant-spin-dot-item{ background-color:#ee8763 !important;}

.HorizonCounter { position:-webkit-sticky; position:sticky; top:0; top:0; padding-left:44px; z-index:11; background:#fff; margin:-40px 0 0; width:100%; height:40px;}
.HorizonCounter span{min-width:43px; text-align:center; display:block; line-height:40px;}
.HorizonCounter button{ border:0; background:transparent; padding:0;}
.gridRightGridInner{ width:100%; }
.HorizonCounterDIV{ position:relative;}
.HorizonCounter ul#myDIV{margin:0; padding:0; list-style:none; border:1px solid #eee; position:absolute; top:40px; left:-1px; background:#fff;}
.HorizonCounter ul#myDIV li{ padding:0 2px;}
.HorizonCounter ul#myDIV li:nth-child(1) button{ transform:rotate(90deg);}
.HorizonCounter ul#myDIV li:nth-child(2) button{ transform:rotate(90deg);}

.GridBorder{ background: #b2b2b2; width:100%; height: 2px; position: -webkit-sticky; position: sticky; left:0; z-index:11; margin:0; top:40px;}
.GridBorderBottom{ background: #b2b2b2; width:100%; height: 2px; position: sticky; left:0; z-index: 11; margin:0; bottom:0;}
.BoxLeftBorder:before {background: #fff;content: '';position: -webkit-sticky;position: sticky;top: 0;left: 0;width: 45px;height: 40px;z-index:14;display: block;margin: -40px 0 0;}
.BoxLeftBorder:after{ content:''; width:2px; height:calc(100% + 0px); position:absolute; top:0; right:0; background:#b2b2b2; z-index:12;}
.BoxRightBorder:before {background: #fff; content:''; position:sticky; top:0; left:0; width:80px; height:40px; z-index:12; display:block; margin:-40px 0 0 -12px;}
.BoxRightBorder:after{ content:''; width:2px; height:calc(100% + 4px); position:absolute; top:0; left:0; background:#b2b2b2;}
.even.BoxRightBorder:after {height: calc(100% + 2px);}

.odd.BoxRightBorder:before,
.even.BoxRightBorder:before{ margin-left:0;}
.even .GridRightCounterLeftWrap:before {height: calc(100% + 44px);}
.odd .GridRightCounterLeftWrap:before {height:100%;}
.even.BoxLeftBorder:after { height:calc(100% + 4px);}

.InRoundLeft.BoxLeftBorder:after{ height:calc(100% + 82px);margin:-82px 0 0;}
.InRoundLeft .GridRightCounter-left{ height:43px; padding:0; font-size:0;}
.InRoundLeft.GridRightCounterLeftWrap{padding-top:42px;}
.InRoundLeft.even.GridRightCounterLeftWrap:before {height:calc(100% + 40px); margin:-82px 0 0; padding:40px 0 0;}

.InRoundLeft.odd.BoxLeftBorder:before{ margin:-44px 0 0;}
.odd.InRoundLeft.GridRightCounterLeftWrap {padding-top:0; height:auto; padding-bottom:0;}
.InRoundLeft + .grid-table-div + .BoxRightBorder:after {height: calc(100% + 0px);}

.pattern-list-contentIMG{ margin:0 0 1.3rem;;}
.pattern-list-contentIMG img{ max-width:100%;border:2px solid #dadada;}
.two-column .ant-input-number{ height:auto; border:0; width:100%;}
.two-column .ant-input-number-handler-wrap {top: 1px; right: 1px; width: 22px; height: calc(100% - 2px);}
.two-column .ant-input-number:focus {box-shadow: none;}

textarea.text-area-input:focus::-moz-placeholder,
input.ant-input:focus:-moz-placeholder { color:transparent; }

textarea.text-area-input:focus::-webkit-input-placeholder,
input.ant-input:focus::-webkit-input-placeholder { color:transparent; }

textarea.text-area-input:focus:-ms-input-placeholder,
input.ant-input:focus:-ms-input-placeholder { color:transparent; }

.FalseDIV .pattern-list-box{background:rgba(0,0,0,.1);}
.FalseDIV:hover .pattern-action:before {content: '';background: transparent;}
.FalseDIV .size-info-box, .FalseDIV .pattern-list-img, .FalseDIV .pattern-list-content { opacity:.5;}
span.colorSection[style="background-color: rgb(255, 255, 255);"]{ border: 2px solid #bbb;}

.HideShowDIV{ margin:20px 0 8px; display:inline-block; vertical-align:top; z-index:2; position:relative;}
.HideShowDIV button{ border:0; padding:13px 20px 12px; box-shadow:0 0 20px 0 rgba(0,0,0,.2);background: #fff; border-radius:4px; box-shadow: 0 0 20px 0 rgba(0,0,0,.1); color:#494d4b; font-size:16px; font-weight:600; text-transform:uppercase;}
.HideShowDIV button:hover{ background:#ee8763; color:#fff;}
.hideShow .grid-left{ display:none;}
.hideShow .grid-right {width:100%;}
.hideShow .stitches-rotation {justify-content: flex-end;}

.HideSpan{ display:block;}
.ShowSpan{ display:none;}
.hideShow .HideSpan{ display:none;}
.hideShow .ShowSpan{ display:block;}
.ant-popover-inner-content h3{ font-size: 16px; margin: 0; padding: 0; line-height: normal;}
.gridRightGridInnerWrap{ height:calc(100% - 40px);}
.ant-popover{ max-width:280px;}

.grid-right{ margin:0 0 25px;}
.StitchBox{ flex-wrap:wrap; margin:0 0 35px;}
.ElementalStitch{ width:28%;}
.CompoundStitch{ width:70%; margin:0 0 0 auto;} 
.ElementalStitch>div, .CompoundStitch>div{ height:100%;;}
.ElementalStitch .grid-box , .CompoundStitch .grid-box { height:calc(100% - 28px);}
.StitchOption{ margin:0 0 20px; position:relative; z-index:12; flex-wrap:wrap;}
.important-div{ margin:0 0 1rem; padding:15px; background: #fff; border-radius: 4px; box-shadow: 0 0 20px 0 rgba(0,0,0,.1); width:125px; }
.grid-colors{ margin:0 1.25rem 1rem 0; padding:15px; background: #fff; border-radius: 4px; box-shadow: 0 0 20px 0 rgba(0,0,0,.1); width:265px; }
.repeat-div{ margin:0 1.25rem 1rem 0; padding:15px; background: #fff; border-radius: 4px; box-shadow: 0 0 20px 0 rgba(0,0,0,.1); width:180px; }

.grid-box ul li .ant-checkbox-checked .ant-checkbox-inner {border-color: #1890ff;}
body .ant-checkbox-wrapper:hover .ant-checkbox-inner, body .ant-checkbox:hover .ant-checkbox-inner, 
body .ant-checkbox-input:focus + .ant-checkbox-inner {border-color: #d7d7d7;}

body .ant-checkbox-inner, body .ant-checkbox-inner::after, body .ant-checkbox-checked::after{ animation:initial; animation-fill-mode:initial;}
body .ant-checkbox-checked::after {border: 2px solid #1890ff;}

.AddMultiline button{font-size: 16px; color: #666; font-weight: 500; margin: 0 5px; border: 0;  background: transparent;}
.AddMultiline button:hover{ color: #666; background:transparent; border:0; box-shadow: none; }
.swatches-picker { width:240px !important; height:140px !important;}
.swatches-picker div[style="height: 240px; overflow-y: scroll;"]{ height:140px !important;}
.HeightWidthWrap .HeightWidth{ width:260px; margin-right:20px;}

/* .ant-select .ant-select-selection,
.ant-select .ant-select-selection--single{border-radius: 4px; box-shadow:2px 4px 4px 0 rgba(0,0,0,.1) !important; width:100%; border:1px solid #dedcdc; padding:7px 15px !important;
line-height:24px; font-size:14px; color:#666; font-weight:500; height:auto; font-family:'Raleway'; letter-spacing:.04em; opacity:1;}
body .ant-select-focused .ant-select-selection, body .ant-select-selection:focus, body .ant-select-selection:active,
body .ant-select .ant-select-selection:hover,
body .ant-select .ant-select-selection--single:hover{border-color:#dedcdc;} */
body .ant-select-selection--single .ant-select-selection__rendered {margin:0;}

.StitchesIMG{ text-align:left; display:block; margin: 0 0 20px;}
.StitchListContent{ margin:0 -10px;}
.StitchListContent .size-list-box {min-height: 344px;}
.instructionWidth .grid-table-div{ margin-right:225px; }

.StitchMarker.repeat-div {max-width:inherit; width:auto;}

.StitchMarker .ant-checkbox input[value="BlackBDR-right"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border:2px dashed #d9d9d9; border-right:3px solid #000;}
.StitchMarker .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="BlackBDR-right"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="BlackBDR-right"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="BlackBDR-right"]:focus + .ant-checkbox-inner{border-right:3px solid #000;}

.StitchMarker .ant-checkbox input[value="BlackBDR-left"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border:2px dashed #d9d9d9; border-left:3px solid #000;}
.StitchMarker .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="BlackBDR-left"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="BlackBDR-left"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="BlackBDR-left"]:focus + .ant-checkbox-inner{ border-left:3px solid #000;}

.StitchMarker .ant-checkbox input[value="TopBottomMarker"] + .ant-checkbox-inner {width:44px; height:44px; background-color:transparent; border:2px dashed #d9d9d9; border-top:3px solid #000; border-bottom:3px solid #000;}
.StitchMarker .ant-checkbox-wrapper:hover .ant-checkbox-checked input[value="TopBottomMarker"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="TopBottomMarker"] + .ant-checkbox-inner, 
.StitchMarker .ant-checkbox:hover input[value="TopBottomMarker"]:focus + .ant-checkbox-inner{border-top:3px solid #000; border-bottom:3px solid #000;}


body .grid-right .grid-right-grid .ant-checkbox-wrapper span .BlackBDR-right:after{ content: ''; background:#000; position: absolute; top: 0; right: 0; width:3px; height: 100%; display: block; z-index: 2; border: 0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .BlackBDR-left::after{ content: ''; background:#000; position: absolute; top: 0; left: 0; width:3px; height: 100%; display: block; z-index: 2; border: 0;}

.ColorDiv span.ant-form-item-children label strong{  display:block; font-size: 14px; color: #999; line-height:26px; letter-spacing: .04em; font-weight: 600; font-family: 'Raleway'; position: relative; margin:0 1rem 0 0;}
.ColorDiv span.ant-form-item-children label button{ width: 45px; height: 45px; margin: 0; background: #ee8763; border-radius: 4px; box-shadow: 2px 4px 4px 0 rgba(0,0,0,.1); border: 1px solid #e67750; padding:0 11px; font-size:20px;}
.ColorDiv span.ant-form-item-children label button svg{ fill:#fff;}
.ColorDiv .popover{ position:absolute; top:100%; left:0; z-index:2;}

.ColorDiv .color-div .ant-checkbox-wrapper {margin: 0 10px 5px 0;    overflow: visible;}
.ColorDiv i.anticon-close{color: #bfbfbf; position: absolute; top: -10px; background: #fff; border-radius: 100%; padding: 3px; left: 46px; box-shadow:0 0 10px 0 rgba(0,0,0,.2);z-index:1;}
.ColorDiv i.anticon-close svg{ width:10px; height:10px;}
.StitchNumberBox { position:absolute; top:.1rem; right:.1rem; font-weight:700; color:#000; text-align:right; font-size:1.1rem;}
.StitchNumber .ant-checkbox input[type="checkbox"] + .ant-checkbox-inner:after{ content:''; background:url('/images/number.svg') no-repeat 50% 50% / 2rem; padding: 0;
width:40px; height:40px; position:absolute; top:0; left:0; display:block; transform:initial; border:0; opacity:1;}
.StitchNumber .ant-checkbox input[type="checkbox"] + .ant-checkbox-inner{ width:44px; height: 44px; background-color: transparent; border: 2px solid #d9d9d9; }

.mystyle{ display:none; list-style:none; margin:0; position:absolute; top:0; left:100%; padding:1rem; background:#fff;}
.mystyle li{ display:block;}
.mystyle li + li{ border-top:1px solid #eee;}
.mystyle li button{ background:transparent; border:0;}

ul#myDIV {margin:0; padding:0; background:#fff; border-radius:4px; box-shadow:0 0 4px 0 rgba(0,0,0,.1); position:absolute; top:44px; left:2px; list-style:none;
z-index: 1; border:1px solid #eee}
ul#myDIV li {padding:0; text-align:left; margin:0; display:block; line-height: 1.5;}
ul#myDIV li button { background-color:transparent; background-position:50% 50%; background-repeat:no-repeat; border:0; padding:.4rem 0; white-space:nowrap; line-height:1.5; font-size:.8rem;
font-weight:500; margin:0; vertical-align:top; width:38px; height:38px; font-size:0; background-size:24px 24px;}
ul#myDIV li + li {border-top: 1px solid #eee;}
ul#myDIV li:nth-child(1) button{ background-image:url('/images/UpArrow.svg');}
ul#myDIV li:nth-child(2) button{ background-image:url('/images/DownArrow.svg');}
ul#myDIV li:nth-child(3) button{ background-image:url('/images/Dustbin.svg');}
.anticon.Type{ background:url('/images/graphic-design.svg'); width: 16px; height: 16px; display: block; background-size: 100px 10px; top:-8px; left:-8px; z-index:1;
background-position:50% 50%; background-repeat:no-repeat; background-color:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.2); position:absolute; border-radius:100px;}

li.BetaLi .ant-form-item label { vertical-align:top;  margin:0;}
.BetaLi .ant-checkbox-inner{box-shadow: 2px 4px 4px 0 rgba(0,0,0,.1); width:26px; height:26px;}
.BetaLi .ant-checkbox-inner::after {top: 10px; left: 7px;  width: 7px; height: 13px;}
.UserWeb{ margin-bottom:1rem;}

.blink{ position: fixed; bottom: 1rem; left: 1rem; font-size: 1.3rem; text-transform: uppercase; color: #ee8763; z-index: 10; background:#eee; padding: .7rem;
font-weight:600; box-shadow: 0 0 20px 0 rgba(238,135,99,.8);}
.ant-popover  h1{font-size:.8rem;}

.upload-images ul{ flex-wrap:wrap;}
.upload-images ul li {margin: 0 1rem .7rem 0;}
.upload-images ul li img {max-width: 140px; border: 5px solid #fff; box-shadow: 0 0 10px 0 rgba(0,0,0,.2); border-radius: 4px;}
.upload-images ul li button.ant-btn.imag-close { position:absolute; top: -9px; right:10px;  z-index:1;  width:24px; height:24px;
padding: 0; border: 0; border-radius: 100px; box-shadow: 0 0 10px 0 rgba(0,0,0,.2);}

body .LinkDiv{ margin:0 0 2rem;}
body .LinkDiv .ant-form-item-control label{ text-align:left; margin:0;}
body .LinkDiv .ant-form-item-control input{ background: transparent; border: 0; box-shadow: none !important; padding: .5rem 0 !important; border-bottom: 1px solid #eee; border-radius: 0;
width: 51%; margin: .2rem 0;}
body .LinkDiv .ant-row.ant-form-item {margin: 0;}
.another-label-cstm .add-another .ant-row.ant-form-item {margin-top: 0;}

.gridRightGridInnerWrap{ width:100%;}
.instructionWidth .instruction-div {min-width: 210px;width: 100%;max-width: 100%;}
.instruction-div .grid-instruction-open {min-width: 100%;}
.HorizonCounter{ z-index:12;}
.instructionWidth .grid-table-div { margin-right:0;}

.BetaLabel{ margin:.5rem 0;}
.BetaLabel>label{ margin-right:1rem;}
.BetaLabel .ant-checkbox-inner{ width:22px; height:22px;}

body .BetaLi .ant-checkbox-checked::after,
body .BetaLabel .ant-checkbox-checked::after{border-color: #ee8763;}

body .BetaLi .ant-checkbox-checked .ant-checkbox-inner,
body .BetaLabel .ant-checkbox-checked .ant-checkbox-inner {background-color: #ee8763; border-color: #ee8763;}
.PageTitleLeft h3{ font-size:1.2rem; line-height:1; color:#ee8763; margin:0; font-weight:700;}

.repeat-div .ant-checkbox input[value="LeftRightRepeat"] + .ant-checkbox-inner {width: 44px;height: 44px;background-color: transparent;border: 2px dashed #d9d9d9;border-left: 2px solid #ee8763;border-right: 2px solid #ee8763;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .LeftRightRepeat:after {content: '';background: #ee8763;position: absolute;bottom: 0;right: 0;width: 3px;height: 100%;display: block;z-index: 3;border: 0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .LeftRightRepeat:before {content: '';background: #ee8763;position: absolute;top: 0;left: 0;width: 3px;height: 100%;display: block;z-index: 3;border: 0;}

.StitchMarker .ant-checkbox input[value="TopBottomstitchMarker"] + .ant-checkbox-inner {width: 44px;height: 44px;background-color: transparent;border: 2px dashed #d9d9d9;border-left: 3px solid #000;border-right: 3px solid #000;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomstitchMarker:before {content: '';background: #000;position: absolute;top: 0;left: 0;width: 3px;height: 100%;display: block;z-index: 2;border: 0;}
body .grid-right .grid-right-grid .ant-checkbox-wrapper span .TopBottomstitchMarker:after {content: '';background: #000;position: absolute;bottom: 0;right: 0;width: 3px;height: 100%;display: block;z-index: 2;border: 0;}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.yarn-filledBox{ margin-bottom:2rem;}
.suggested-Wrap{ padding-right:2.5rem; width:100%; position:relative; }
.suggested-Wrap>i{ position:absolute; top:10px; right:0;}

/****** Media start it *****/
@media(max-width:1553px) and (min-width:1321px){
  .pattern-list-box{ margin:0;} 
}

@media(max-width:1320px){
  .difficulty .d-flex{ flex-wrap:wrap;}
  .difficulty>.d-flex>ul,
  .difficulty .d-flex span{ width:100%;}
  .difficulty>.d-flex>ul>li{ width:calc(25% - 5px);}
  .difficulty h4 { margin:0;}
  .slick-slide>div>div { padding:15px 10px;}
  .pattern-list {width: calc(100% + 20px); margin:-20px -10px 20px;}

  .grid-right-head-left {padding-left: 15px;}
  .grid-right-head-left strong {font-size:13px; line-height:normal;}
  .grid-edit img{ max-width:18px;}
  .gridDelete img{ max-width:18px;}
 
  .grid-nav {margin:0; padding:0;}
  .grid-nav img{max-width:24px;}
}  

@media(max-width:1199px){
  .pattern-list-box {padding: 15px;}
  .pattern-list-img {height: 200px; margin-bottom:15px;}
  .pattern-list-content h2 {font-size: 18px; line-height: normal; margin: 0 0 10px; text-overflow: initial; white-space: initial; height:42px; color: #ee8763;}
  .author-price {padding: 0 0 10px 0;}
  .author-price h6 {position: relative; margin:5px 0 0;}
  .author-price + div h4{ margin-bottom:10px;}

  .grid-wrap{ flex-wrap:wrap;}
  .grid-left { max-width:100%;}
  .grid-right { width:100%;}
  .grid-box-wrap.repeat-div, .important-div, .grid-box-wrap.grid-colors{ display:inline-block; vertical-align:top; margin-right:30px;}
  .grid-box-wrap.grid-colors h2 button svg{height: 15px;}

}
@media(max-width:1024px){
  .left-panel{ opacity:0; left:-310px; }
  .header-toggle{ opacity:1;}
  .content-wrap { padding:90px 25px 0 25px;}
  .header-toggle { opacity:1; width:auto; margin:0 20px 0 0;}
  .header-top {top: 20px; left: 106px; width: calc(100% - 130px);}
  .sidebar-toggle{ display:none;}
  .smallview-toggle { display:block; position:absolute; top:20px; left:25px; z-index: 10; box-shadow:0 5px 20px 0 rgba(0,0,0,.1); background:#fff; padding:15px 15px 14px; border-radius:4px;transition: ease-in-out .5s;}
  .smallview-toggle button{ background:transparent; border:0;  }  
  .openSider .left-panel {left: 0; opacity: 1; z-index: 3;}
  .openSider .content-wrap::before{ content:''; background:rgba(0,0,0,.8); width:100%; height:100%; position:absolute; top:0; right:0; display:block; z-index:1;}
  .sidebar-toggle button svg:hover path {fill: rgb(73, 77, 75);}
  .openSider .smallview-toggle{ position:absolute; top:30px; left:225px; z-index:10; box-shadow:none; background:#fff; padding:10px; border-radius:4px;}
  .author-price h6 span, .author-price h6 strong, .author-price + div h4,  .difficulty h4, .difficulty>.d-flex span, .author-price h6,
  .author-price h5 {font-size: 13px;}
  .pattern-list-content h2{ margin:0; height:auto;}
  .pattern-action ul li button {width: 135px; padding:10px 10px 11px;}
  .pattern-action ul li button span{ font-size:13px;}
  .add-color-popup button {height: 28px; margin: 0 0 0 10px;}
  

  
}
@media(max-width:991px){
  .white-box{ padding:20px;}
  .filled-ul li.half-width { width:100%;}
  .ant-row.ant-form-item { margin:0 0 15px;}
  ul.filled-ul li label, .ant-form-item-control label {margin: 0 0 5px; font-size: 13px; letter-spacing: 0;}
  .ant-form-item-control-wrapper .ant-form-item-control textarea, 
  .ant-form-item-control-wrapper .ant-form-item-control input {padding: 6px 12px !important; font-size: 13px}
  .ant-form-item-control-wrapper .ant-form-item-control textarea {height: 140px;}
  .picture-card .ant-form-item .ant-upload-select-picture-card {width: calc(100% - 52px); padding: 10px 15px !important; height: 38px;}
  .picture-card .ant-form-item .ant-upload-select-picture-card>span>div {width:38px; height:38px; padding: 6px 0 !important;}
  .filled-ul li.btm-btn { padding:20px; margin: 0; width: 100%;}
  .picture-card .ant-form-item .ant-col.ant-form-item-label {top: 29px;}
  .picture-card .ant-row.ant-form-item>.ant-col.ant-form-item-label label{ font-size:13px; font-weight:500;}
  .suggested-needles .ant-select-selection--single {padding: 3px 0 !important; font-size: 13px;}
  .add-another-needle {  margin: 15px 0 30px;}
  .grid-right-head-left strong span {display:block;}
  .neddles_li li {padding:0 0 15px; width:100%;}
  .suggested-needles-box .neddles_li { margin: 0; width: 100%; flex-wrap: wrap;}
  i.anticon-minus-circle-o{ margin-right:0;}
  .grid-nav span {margin-right: 10px; font-size: 14px;}
  .AddMultiline button {font-size:14px; margin:0;}
  .grid-edit button span, .gridDelete button span {font-size:13px; margin:0 5px; display:block;}
  .grid-right-head-left strong { margin-right:20px;}


}


@media(max-width:767px){
  .pattern-list-box{ padding:15px;}
  .pattern-list-img {margin: 0 0 15px; height: 180px;}
  .pattern-list-content h2 {font-size: 18px; line-height: 100%; margin: 0 0 10px; text-overflow: initial; white-space: initial; height: auto; overflow: visible; min-height:36px;}
  .author-price { padding:0;}
  .author-price h6{ position:relative; display:block;}
  .author-price h6 span{ font-size:13px;}
  .author-price h6 strong{ font-size:15px;}
  .purchase { margin:0 0 10px;}
  .page-title h2 {font-size:20px; line-height:34px;}
  button.primary-btn {line-height:18px; font-size:13px; padding:8px 10px 7px; height:auto;}
  .page-title { margin:0 0 20px; }
  .header-dropdown>a {padding: 9px 30px 9px 10px; font-size: 13px; min-width: 110px;}
  .header-search .ant-input-affix-wrapper .ant-input{ min-width:230px; padding:9px 15px; font-size:14px;}
  .header-search .ant-input-affix-wrapper i { font-size:16px;}
    .content-wrap { padding:70px 15px 0 15px;}
  .header-top {left:76px; width:calc(100% - 90px); top:15px;}
  .header-toggle button {padding: 10px;}
  .header-toggle button svg{ vertical-align:top; }
  .header-toggle {margin:0 10px 0 0;}
  .smallview-toggle {top: 15px; left:15px; padding:8px 10px 9px;}
  .login-page { padding:40px 15px; background-size:cover;}
  .login-box .ant-form { padding:0 20px;}
  div.ant-row.ant-form-item.login-submit-btn {margin:50px -20px 0; padding:20px;}
  a.login-form-forgot{ letter-spacing:0; }
  div.ant-row.ant-form-item.login-submit-btn button[type="submit"]{ padding:6px 15px; font-size:13px;}
  .pattern_wrapper>div>div { -ms-flex:0 0 50%; flex:0 0 50%; max-width:50%;}
  .ant-select-combobox .ant-select-search__field__wrap {min-width: 100%;}
  .suggested-needles-box .neddles_li {margin:0; width:100%; flex-wrap:wrap;}
  .neddles_li li {padding:0;}
  .neddles_li .ant-select-selection__rendered {margin-left: 0;}
  .add-another-needle { margin:0 0 20px;}
  .add-another .ant-row.ant-form-item { margin:-5px 0 0;}
  ul.filled-ul li label, .ant-form-item-control label { margin:0;}
  .multicopy-li .ant-form-item { width:calc(50% - 10px);}
  .multicopy-li li {margin-bottom:0;}
  .another-label { margin-bottom:15px;}
  .header-search .ant-select-search__field__wrap {padding: 3px 40px 3px 15px;}
  .header-search .ant-select-auto-complete.ant-select .ant-select-selection__rendered {line-height:38px;}
  .header-search .ant-select-selection__placeholder, .header-search .ant-select-search__field__placeholder {top: 9px;}
  .tools button.zoom-line {width: 180px; margin: 9px 5px;}
  .grid-box ul {overflow-x:auto;}
  .ant-modal-root .ant-modal{ min-width:280px; max-width:280px; width:auto !important;}
  .ant-modal-content .ant-modal-body { padding:15px;}
  .StitchBox {flex-wrap:wrap; margin:0;}
  .add-color-popup button {margin-left:10px;}
  .ElementalStitch { width:100%; margin:0 0 25px;}
  .CompoundStitch { width:100%; margin:0 0 25px;}
  .StitchOption {margin:0 ; flex-wrap:wrap;}
  .grid-box-wrap.repeat-div{display:inline-block; vertical-align: top; margin:0 1.25rem 1.25rem 0; width:auto; }
  .important-div{display:inline-block; vertical-align: top; margin:0 1.25rem 1.25rem 0; width:auto;}
  .grid-box-wrap.grid-colors {display:inline-block; vertical-align: top; margin:0 1.25rem 1.25rem 0; width:auto;}
  .grid-right-title {flex-wrap: wrap;}
  .grid-right-title h2{ margin:0 1rem 0 0;}
  .stitches-rotation { margin:0;}
  .grid-right-head { flex-wrap:wrap; padding:0; background: transparent;}
  .grid-right-box {padding: 15px;}
  .grid-right-head-left { padding:0 0 15px; justify-content:space-between; width:100%; border-bottom:1px solid rgba(173, 166, 166, 0.2); margin-bottom:15px;}
  .grid-nav { margin:0; height:auto;}
  .grid-edit, .gridDelete{ margin-right:10px;}
  .grid-edit button span, .gridDelete button span {display:initial;}
  .gridDelete button { margin:0;}
  .grid-right-head-left strong {margin-right:0;}
  .grid-right-head-left strong span {display: block; margin-left:5px;}
  .swatches-picker {width:270px !important;}
  .btm-btn .ant-form-item-control button { padding:9px 15px; margin:0 5px;}
  .LinkDiv{ position:relative; top:0; left:0; margin:0;}  

}

@media(max-width:499px){
  .header-search{ position:absolute; top: 49px; left: 0; width: 100%;}
  .content-wrap {padding: 120px 15px 0 15px;}
  .pattern_wrapper>div>div { -ms-flex:0 0 100%; flex:0 0 100%; max-width:100%;}
  .header-top {left:15px; width:calc(100% - 30px); top:15px;}
  .important-div, .StitchOption  .grid-box-wrap{ margin:0 0 1.25rem; width:100%;}  
}
.multicopy-li.full-width-row .ant-form-item {
    width: 100%;
}


